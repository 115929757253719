import styled from "styled-components";

const Blog = () => {
  return (
    <BlogWrap>
      <a
        href='https://medium.com/keytalk-blog'
        target='_blank'
        rel='noreferrer'
      >
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M16 0.5C24.5604 0.5 31.5 7.43959 31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5Z'
            stroke='#667085'
          />
          <path
            d='M9.9 12.2016C9.9 12.0016 9.8 11.8016 9.7 11.7016L8.2 9.90156V9.60156H12.8L16.4 17.5016L19.6 9.60156H24V9.90156L22.7 11.1016C22.6 11.2016 22.5 11.3016 22.6 11.5016V20.5016C22.6 20.6016 22.6 20.8016 22.7 20.9016L23.9 22.1016V22.4016H17.6V22.1016L18.9 20.8016C19 20.7016 19 20.6016 19 20.4016V13.1016L15.4 22.2016H15L10.8 13.1016V19.2016C10.8 19.5016 10.9 19.7016 11 19.9016L12.7 21.9016V22.2016H8V21.9016L9.7 19.9016C9.9 19.7016 10 19.5016 9.9 19.2016V12.2016Z'
            fill='#667085'
          />
        </svg>
      </a>
      <a
        href='https://www.linkedin.com/company/mycelebs'
        target='_blank'
        rel='noreferrer'
      >
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='0.5'
            y='0.5'
            width='31'
            height='31'
            rx='15.5'
            stroke='#667085'
          />
          <path
            d='M22.3984 16.5971V21.0096H19.8155V16.8661C19.8155 15.8437 19.4388 15.1442 18.524 15.1442C17.8244 15.1442 17.394 15.6285 17.2325 16.059C17.1787 16.2204 17.1249 16.4356 17.1249 16.7047V21.0096H14.5419C14.5419 21.0096 14.5957 14.0141 14.5419 13.3146H17.1249V14.3908C17.4478 13.8527 18.0935 13.0993 19.4388 13.0993C21.107 13.0993 22.3984 14.2294 22.3984 16.5971ZM11.8514 9.60156C10.9904 9.60156 10.3984 10.1935 10.3984 10.9469C10.3984 11.7002 10.9366 12.2921 11.7975 12.2921C12.7123 12.2921 13.2505 11.7002 13.2505 10.9469C13.3043 10.1397 12.7661 9.60156 11.8514 9.60156ZM10.5599 21.0096H13.1428V13.3146H10.5599V21.0096Z'
            fill='#667085'
          />
        </svg>
      </a>
      <a
        href='https://www.facebook.com/mycelebscom'
        target='_blank'
        rel='noreferrer'
      >
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='0.5'
            y='0.5'
            width='31'
            height='31'
            rx='15.5'
            stroke='#667085'
          />
          <path
            d='M17.3288 16.2903H20.3065L20.7741 13.2469H17.3282V11.5835C17.3282 10.3192 17.7388 9.19813 18.9143 9.19813H20.8031V6.54223C20.4713 6.49714 19.7694 6.39844 18.4431 6.39844C15.6737 6.39844 14.0501 7.86988 14.0501 11.2222V13.2469H11.2031V16.2903H14.0501V24.6553C14.6139 24.7406 15.185 24.7984 15.7712 24.7984C16.3011 24.7984 16.8183 24.7497 17.3288 24.6802V16.2903Z'
            fill='#667085'
          />
        </svg>
      </a>
      <a href='https://www.youtube.com/@user-or7zn2od2h/featured'>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='0.5'
            y='0.5'
            width='31'
            height='31'
            rx='15.5'
            stroke='#667085'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.01312 21.6455C8.69931 21.5526 8.41298 21.3874 8.17829 21.1639C7.9436 20.9403 7.76745 20.665 7.66468 20.361C7.07746 18.7899 6.90347 12.2295 8.03441 10.9345C8.41081 10.5132 8.94224 10.2538 9.51334 10.2126C12.5473 9.89414 21.9211 9.93661 22.9977 10.3188C23.3005 10.4147 23.5771 10.5762 23.8069 10.7913C24.0366 11.0064 24.2135 11.2695 24.3244 11.5608C24.966 13.1849 24.9877 19.0871 24.2374 20.6476C24.0383 21.0538 23.7051 21.3828 23.2913 21.5818C22.1603 22.1338 10.5138 22.1231 9.01312 21.6455ZM13.8305 18.567L19.2677 15.807L13.8305 13.0257V18.567Z'
            fill='#667085'
          />
        </svg>
      </a>
    </BlogWrap>
  );
};

export default Blog;

const BlogWrap = styled.div`
  > a {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
