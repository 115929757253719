const TrandLogo = () => {
  return (
    <svg
      width='117'
      height='24'
      viewBox='0 0 117 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M68.256 19.0981V1.09201H72.0493V19.0981H68.256Z' fill='white' />
      <path
        d='M68.256 19.0981V1.09201H72.0493V19.0981H68.256Z'
        fill='url(#paint0_linear_470_5703)'
      />
      <path
        d='M93.0511 19.2922C92.4026 19.2922 91.8434 19.0738 91.3733 18.637C90.9194 18.184 90.6924 17.6097 90.6924 16.9141C90.6924 16.2184 90.9194 15.6603 91.3733 15.2396C91.8434 14.8028 92.4026 14.5844 93.0511 14.5844C93.7157 14.5844 94.275 14.8028 94.7289 15.2396C95.1828 15.6603 95.4097 16.2184 95.4097 16.9141C95.4097 17.6097 95.1828 18.184 94.7289 18.637C94.275 19.0738 93.7157 19.2922 93.0511 19.2922Z'
        fill='white'
      />
      <path
        d='M93.0511 19.2922C92.4026 19.2922 91.8434 19.0738 91.3733 18.637C90.9194 18.184 90.6924 17.6097 90.6924 16.9141C90.6924 16.2184 90.9194 15.6603 91.3733 15.2396C91.8434 14.8028 92.4026 14.5844 93.0511 14.5844C93.7157 14.5844 94.275 14.8028 94.7289 15.2396C95.1828 15.6603 95.4097 16.2184 95.4097 16.9141C95.4097 17.6097 95.1828 18.184 94.7289 18.637C94.275 19.0738 93.7157 19.2922 93.0511 19.2922Z'
        fill='url(#paint1_linear_470_5703)'
      />
      <path
        d='M112.745 19.0981V6.04247H116.538V19.0981H112.745ZM114.641 4.22245C113.944 4.22245 113.377 4.02022 112.939 3.61577C112.502 3.21133 112.283 2.70981 112.283 2.11122C112.283 1.51264 112.502 1.01112 112.939 0.606674C113.377 0.202225 113.944 0 114.641 0C115.338 0 115.906 0.194136 116.343 0.582408C116.781 0.954501 117 1.43984 117 2.03842C117 2.66936 116.781 3.19515 116.343 3.61577C115.922 4.02022 115.355 4.22245 114.641 4.22245Z'
        fill='white'
      />
      <path
        d='M112.745 19.0981V6.04247H116.538V19.0981H112.745ZM114.641 4.22245C113.944 4.22245 113.377 4.02022 112.939 3.61577C112.502 3.21133 112.283 2.70981 112.283 2.11122C112.283 1.51264 112.502 1.01112 112.939 0.606674C113.377 0.202225 113.944 0 114.641 0C115.338 0 115.906 0.194136 116.343 0.582408C116.781 0.954501 117 1.43984 117 2.03842C117 2.66936 116.781 3.19515 116.343 3.61577C115.922 4.02022 115.355 4.22245 114.641 4.22245Z'
        fill='url(#paint2_linear_470_5703)'
      />
      <path
        d='M11.4528 2.11122H15.8297L8.78283 9.67947L16.2431 19.0981H11.6473L6.19578 12.4339L3.91487 14.8101V19.0981H0V2.11122H3.91487V10.0382L11.4528 2.11122Z'
        fill='white'
      />
      <path
        d='M11.4528 2.11122H15.8297L8.78283 9.67947L16.2431 19.0981H11.6473L6.19578 12.4339L3.91487 14.8101V19.0981H0V2.11122H3.91487V10.0382L11.4528 2.11122Z'
        fill='url(#paint3_linear_470_5703)'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M22.4624 19.2922C20.9711 19.2922 19.658 19.001 18.5232 18.4186C17.4047 17.8362 16.5374 17.0435 15.9214 16.0404C15.3054 15.0212 14.9974 13.8645 14.9974 12.5703C14.9974 11.2599 15.2973 10.1031 15.8971 9.1001C16.5131 8.08089 17.348 7.28817 18.4017 6.72194C19.4554 6.13954 20.6468 5.84833 21.9761 5.84833C23.2568 5.84833 24.4077 6.12336 25.429 6.67341C26.4665 7.20728 27.2851 7.98382 27.8849 9.00303C28.4847 10.0061 28.7846 11.2113 28.7846 12.6188C28.7846 12.7644 28.7765 12.9343 28.7603 13.1284C28.7441 13.3064 28.7278 13.4762 28.7116 13.638H18.8136C18.89 13.9856 19.012 14.3091 19.1798 14.6087C19.504 15.1426 19.9498 15.5551 20.5172 15.8463C21.1007 16.1213 21.7816 16.2589 22.5597 16.2589C23.2568 16.2589 23.8646 16.1537 24.3834 15.9434C24.9183 15.7331 25.4047 15.4176 25.8423 14.997L27.8606 17.181C27.2608 17.8605 26.507 18.3863 25.5992 18.7583C24.6914 19.1143 23.6458 19.2922 22.4624 19.2922ZM23.7025 9.14864C24.1889 9.42366 24.5698 9.81193 24.8454 10.3134C25.0283 10.6463 25.1505 11.0184 25.212 11.4297H18.7698C18.8329 11.0253 18.9533 10.6613 19.1311 10.3377C19.4067 9.82002 19.7877 9.42366 20.274 9.14864C20.7765 8.85743 21.352 8.71183 22.0004 8.71183C22.6489 8.71183 23.2162 8.85743 23.7025 9.14864Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M22.4624 19.2922C20.9711 19.2922 19.658 19.001 18.5232 18.4186C17.4047 17.8362 16.5374 17.0435 15.9214 16.0404C15.3054 15.0212 14.9974 13.8645 14.9974 12.5703C14.9974 11.2599 15.2973 10.1031 15.8971 9.1001C16.5131 8.08089 17.348 7.28817 18.4017 6.72194C19.4554 6.13954 20.6468 5.84833 21.9761 5.84833C23.2568 5.84833 24.4077 6.12336 25.429 6.67341C26.4665 7.20728 27.2851 7.98382 27.8849 9.00303C28.4847 10.0061 28.7846 11.2113 28.7846 12.6188C28.7846 12.7644 28.7765 12.9343 28.7603 13.1284C28.7441 13.3064 28.7278 13.4762 28.7116 13.638H18.8136C18.89 13.9856 19.012 14.3091 19.1798 14.6087C19.504 15.1426 19.9498 15.5551 20.5172 15.8463C21.1007 16.1213 21.7816 16.2589 22.5597 16.2589C23.2568 16.2589 23.8646 16.1537 24.3834 15.9434C24.9183 15.7331 25.4047 15.4176 25.8423 14.997L27.8606 17.181C27.2608 17.8605 26.507 18.3863 25.5992 18.7583C24.6914 19.1143 23.6458 19.2922 22.4624 19.2922ZM23.7025 9.14864C24.1889 9.42366 24.5698 9.81193 24.8454 10.3134C25.0283 10.6463 25.1505 11.0184 25.212 11.4297H18.7698C18.8329 11.0253 18.9533 10.6613 19.1311 10.3377C19.4067 9.82002 19.7877 9.42366 20.274 9.14864C20.7765 8.85743 21.352 8.71183 22.0004 8.71183C22.6489 8.71183 23.2162 8.85743 23.7025 9.14864Z'
        fill='url(#paint4_linear_470_5703)'
      />
      <path
        d='M31.7477 24C31.0669 24 30.3941 23.8948 29.7295 23.6845C29.0648 23.4742 28.5218 23.183 28.1003 22.8109L29.4863 20.1173C29.7781 20.3761 30.1104 20.5784 30.4833 20.724C30.8723 20.8696 31.2533 20.9424 31.6261 20.9424C32.1611 20.9424 32.5826 20.8129 32.8906 20.5541C33.2148 20.3114 33.5066 19.8989 33.7659 19.3165L33.8124 19.2071L28.1489 6.04247H32.0638L35.7361 14.909L39.4315 6.04247H43.0789L37.1702 19.8989C36.7487 20.9505 36.2624 21.7755 35.7112 22.3741C35.1763 22.9727 34.5765 23.3933 33.9118 23.636C33.2634 23.8787 32.542 24 31.7477 24Z'
        fill='white'
      />
      <path
        d='M31.7477 24C31.0669 24 30.3941 23.8948 29.7295 23.6845C29.0648 23.4742 28.5218 23.183 28.1003 22.8109L29.4863 20.1173C29.7781 20.3761 30.1104 20.5784 30.4833 20.724C30.8723 20.8696 31.2533 20.9424 31.6261 20.9424C32.1611 20.9424 32.5826 20.8129 32.8906 20.5541C33.2148 20.3114 33.5066 19.8989 33.7659 19.3165L33.8124 19.2071L28.1489 6.04247H32.0638L35.7361 14.909L39.4315 6.04247H43.0789L37.1702 19.8989C36.7487 20.9505 36.2624 21.7755 35.7112 22.3741C35.1763 22.9727 34.5765 23.3933 33.9118 23.636C33.2634 23.8787 32.542 24 31.7477 24Z'
        fill='url(#paint5_linear_470_5703)'
      />
      <path
        d='M50.4626 19.2922C48.9226 19.2922 47.723 18.9039 46.8639 18.1274C46.0047 17.3347 45.5751 16.1618 45.5751 14.6087V9.2457H43.5569V6.33367H45.5751V3.1547H49.3684V6.33367H52.6267V9.2457H49.3684V14.5602C49.3684 15.1102 49.5143 15.5389 49.8061 15.8463C50.0979 16.1375 50.495 16.2831 50.9976 16.2831C51.5974 16.2831 52.108 16.1213 52.5295 15.7978L53.5508 18.4671C53.1617 18.7422 52.6916 18.9525 52.1404 19.0981C51.6055 19.2275 51.0462 19.2922 50.4626 19.2922Z'
        fill='white'
      />
      <path
        d='M50.4626 19.2922C48.9226 19.2922 47.723 18.9039 46.8639 18.1274C46.0047 17.3347 45.5751 16.1618 45.5751 14.6087V9.2457H43.5569V6.33367H45.5751V3.1547H49.3684V6.33367H52.6267V9.2457H49.3684V14.5602C49.3684 15.1102 49.5143 15.5389 49.8061 15.8463C50.0979 16.1375 50.495 16.2831 50.9976 16.2831C51.5974 16.2831 52.108 16.1213 52.5295 15.7978L53.5508 18.4671C53.1617 18.7422 52.6916 18.9525 52.1404 19.0981C51.6055 19.2275 51.0462 19.2922 50.4626 19.2922Z'
        fill='url(#paint6_linear_470_5703)'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M62.2454 11.4297C62.2454 10.6208 61.9942 9.98989 61.4916 9.53691C61.0053 9.08392 60.2515 8.85743 59.2302 8.85743C58.5332 8.85743 57.8442 8.97068 57.1634 9.19717C56.4987 9.40748 55.9314 9.69869 55.4613 10.0708L54.0996 7.42568C54.8128 6.92417 55.672 6.5359 56.6771 6.26087C57.6821 5.98585 58.7034 5.84833 59.7409 5.84833C61.7348 5.84833 63.2829 6.31749 64.3852 7.25581C65.4875 8.19414 66.0387 9.65824 66.0387 11.6481V19.0981H62.4886L62.4011 17.6147C62.1253 18.0304 61.7654 18.3712 61.3214 18.637C60.5919 19.0738 59.6517 19.2922 58.5008 19.2922C57.4795 19.2922 56.6041 19.1223 55.8746 18.7826C55.1452 18.4267 54.5859 17.9494 54.1968 17.3509C53.8078 16.7523 53.6133 16.0809 53.6133 15.3367C53.6133 14.5602 53.7997 13.8807 54.1725 13.2983C54.5616 12.7159 55.1695 12.2629 55.9962 11.9393C56.823 11.5996 57.901 11.4297 59.2302 11.4297H62.2454ZM62.2454 13.638V14.9727C62.0185 15.5551 61.6537 16 61.1512 16.3074C60.6487 16.5986 60.0813 16.7442 59.4491 16.7442C58.7844 16.7442 58.2576 16.6067 57.8685 16.3316C57.4957 16.0404 57.3093 15.6522 57.3093 15.1668C57.3093 14.73 57.4714 14.366 57.7956 14.0748C58.136 13.7836 58.752 13.638 59.6436 13.638H62.2454Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M62.2454 11.4297C62.2454 10.6208 61.9942 9.98989 61.4916 9.53691C61.0053 9.08392 60.2515 8.85743 59.2302 8.85743C58.5332 8.85743 57.8442 8.97068 57.1634 9.19717C56.4987 9.40748 55.9314 9.69869 55.4613 10.0708L54.0996 7.42568C54.8128 6.92417 55.672 6.5359 56.6771 6.26087C57.6821 5.98585 58.7034 5.84833 59.7409 5.84833C61.7348 5.84833 63.2829 6.31749 64.3852 7.25581C65.4875 8.19414 66.0387 9.65824 66.0387 11.6481V19.0981H62.4886L62.4011 17.6147C62.1253 18.0304 61.7654 18.3712 61.3214 18.637C60.5919 19.0738 59.6517 19.2922 58.5008 19.2922C57.4795 19.2922 56.6041 19.1223 55.8746 18.7826C55.1452 18.4267 54.5859 17.9494 54.1968 17.3509C53.8078 16.7523 53.6133 16.0809 53.6133 15.3367C53.6133 14.5602 53.7997 13.8807 54.1725 13.2983C54.5616 12.7159 55.1695 12.2629 55.9962 11.9393C56.823 11.5996 57.901 11.4297 59.2302 11.4297H62.2454ZM62.2454 13.638V14.9727C62.0185 15.5551 61.6537 16 61.1512 16.3074C60.6487 16.5986 60.0813 16.7442 59.4491 16.7442C58.7844 16.7442 58.2576 16.6067 57.8685 16.3316C57.4957 16.0404 57.3093 15.6522 57.3093 15.1668C57.3093 14.73 57.4714 14.366 57.7956 14.0748C58.136 13.7836 58.752 13.638 59.6436 13.638H62.2454Z'
        fill='url(#paint7_linear_470_5703)'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M106.779 11.4297C106.779 10.6208 106.527 9.98989 106.025 9.53691C105.539 9.08392 104.785 8.85743 103.763 8.85743C103.066 8.85743 102.377 8.97068 101.697 9.19717C101.032 9.40748 100.465 9.69869 99.9945 10.0708L98.6328 7.42568C99.3461 6.92417 100.205 6.5359 101.21 6.26087C102.215 5.98585 103.237 5.84833 104.274 5.84833C106.268 5.84833 107.816 6.31749 108.918 7.25581C110.021 8.19414 110.572 9.65824 110.572 11.6481V19.0981H107.022L106.934 17.6147C106.659 18.0304 106.299 18.3712 105.855 18.637C105.125 19.0738 104.185 19.2922 103.034 19.2922C102.013 19.2922 101.137 19.1223 100.408 18.7826C99.6784 18.4267 99.1191 17.9494 98.7301 17.3509C98.341 16.7523 98.1465 16.0809 98.1465 15.3367C98.1465 14.5602 98.3329 13.8807 98.7058 13.2983C99.0948 12.7159 99.7027 12.2629 100.529 11.9393C101.356 11.5996 102.434 11.4297 103.763 11.4297H106.779ZM106.779 13.638V14.9727C106.552 15.5551 106.187 16 105.684 16.3074C105.182 16.5986 104.615 16.7442 103.982 16.7442C103.318 16.7442 102.791 16.6067 102.402 16.3316C102.029 16.0404 101.843 15.6522 101.843 15.1668C101.843 14.73 102.005 14.366 102.329 14.0748C102.669 13.7836 103.285 13.638 104.177 13.638H106.779Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M106.779 11.4297C106.779 10.6208 106.527 9.98989 106.025 9.53691C105.539 9.08392 104.785 8.85743 103.763 8.85743C103.066 8.85743 102.377 8.97068 101.697 9.19717C101.032 9.40748 100.465 9.69869 99.9945 10.0708L98.6328 7.42568C99.3461 6.92417 100.205 6.5359 101.21 6.26087C102.215 5.98585 103.237 5.84833 104.274 5.84833C106.268 5.84833 107.816 6.31749 108.918 7.25581C110.021 8.19414 110.572 9.65824 110.572 11.6481V19.0981H107.022L106.934 17.6147C106.659 18.0304 106.299 18.3712 105.855 18.637C105.125 19.0738 104.185 19.2922 103.034 19.2922C102.013 19.2922 101.137 19.1223 100.408 18.7826C99.6784 18.4267 99.1191 17.9494 98.7301 17.3509C98.341 16.7523 98.1465 16.0809 98.1465 15.3367C98.1465 14.5602 98.3329 13.8807 98.7058 13.2983C99.0948 12.7159 99.7027 12.2629 100.529 11.9393C101.356 11.5996 102.434 11.4297 103.763 11.4297H106.779ZM106.779 13.638V14.9727C106.552 15.5551 106.187 16 105.684 16.3074C105.182 16.5986 104.615 16.7442 103.982 16.7442C103.318 16.7442 102.791 16.6067 102.402 16.3316C102.029 16.0404 101.843 15.6522 101.843 15.1668C101.843 14.73 102.005 14.366 102.329 14.0748C102.669 13.7836 103.285 13.638 104.177 13.638H106.779Z'
        fill='url(#paint8_linear_470_5703)'
      />
      <path
        d='M83.6913 6.04247H88.2141L82.7658 11.57L88.7004 19.0981H84.1047L79.9799 13.9864L78.1473 15.7845V19.0981H74.354V1.09201H78.1473V11.2888L83.6913 6.04247Z'
        fill='white'
      />
      <path
        d='M83.6913 6.04247H88.2141L82.7658 11.57L88.7004 19.0981H84.1047L79.9799 13.9864L78.1473 15.7845V19.0981H74.354V1.09201H78.1473V11.2888L83.6913 6.04247Z'
        fill='url(#paint9_linear_470_5703)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_470_5703'
          x1='-1.72386e-06'
          y1='24'
          x2='21.0692'
          y2='-27.3562'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#3494E6' />
          <stop offset='1' stop-color='#EC6EAD' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TrandLogo;
