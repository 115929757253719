import * as React from "react";
import styled from "styled-components";
import Blog from "./Blog";
import { commonStyle } from "../assets/common";
const Footer = (props) => {
  return (
    <FooterWrap>
      <div className='first-info'>
        <ul>
          <li>CEO · Kyle Doh</li>
          <li>
            7, Teheran-ro 5-gil, Gangnam-gu, Seoul, 06134 Republic of Korea
          </li>
          <li>Business license no · 220-88-90953</li>
          <li>
            <span>Report no · 03391, Gangnam</span>{" "}
            <a
              href='https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208890953'
              target='_blank'
              class='business_info'
              rel='noreferrer'
            >
              <span>Business info.</span>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6 12L10 8L6 4'
                  stroke='#33465B'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>{" "}
            </a>
          </li>
          <li>Contact · 82 2-6245-7403</li>
          <li>business@mycelebs.com</li>
        </ul>
        <a
          href='https://www.producthunt.com/posts/movie-deep-search-by-ai-keytalk?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-movie&#0045;deep&#0045;search&#0045;by&#0045;ai&#0045;keytalk'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=379544&theme=light'
            alt='Product Hunt Badge'
            style={{ width: "250px", height: "54px" }}
            width='250'
            height='54'
          />
        </a>
      </div>
      <div className='copy-wrap'>
        <p>Copyright &copy; Keytalk.ai All rights reserved.</p>

        <Blog />
      </div>
    </FooterWrap>
  );
};
export default Footer;
const FooterWrap = styled.footer`
  /* height: calc(100vh - 284px); */
  padding: 48px 16px;
  @media screen and (${commonStyle.tablet}) {
  }
  @media screen and (min-width: ${commonStyle.pc}) {
    padding: 48px 64px 64px 64px;
    max-width: 1440px;

    margin: 0 auto;

    background: #ffffff;
  }
  > .first-info {
    border-bottom: 1px solid #eaecf0;
    padding-bottom: 48px;
    @media screen and (min-width: ${commonStyle.pc}) {
      display: flex;
      justify-content: space-between;
      padding-bottom: 102px;
      border-bottom: 1px solid #eaecf0;
      /* margin-right: 35px; */
    }
    > ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 48px;
      @media screen and (min-width: ${commonStyle.pc}) {
        margin-bottom: 0;
      }
      > li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        letter-spacing: -0.02em;

        /* Gray/600 */

        color: #475467;
        @media screen and (min-width: ${commonStyle.pc}) {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          /* Gray/600 */

          color: #475467;
        }
        margin-bottom: 4px;
        &:nth-child(4) {
          display: flex;
          align-items: center;
          > span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */

            display: flex;
            align-items: center;

            /* Gray/600 */

            color: #475467;
            @media screen and (min-width: ${commonStyle.pc}) {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              /* Gray/600 */

              color: #475467;
            }
            margin-right: 8px;
          }
          > a {
            display: flex;
            background: #ffffff;
            border: 1px solid #d7d9e2;
            border-radius: 4px;
            align-items: center;
            text-decoration: none;
            padding: 2px 0 2px 4px;
            > span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              line-height: 12px;
              /* identical to box height, or 120% */

              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: -0.02em;

              color: #33465b;
            }
          }
        }
      }
    }
  }
  > .copy-wrap {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    @media screen and (min-width: ${commonStyle.pc}) {
      padding-top: 32px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    /* padding-right: 35px; */
    > p {
      margin: 0;
      margin-bottom: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      text-align: center;

      /* Gray/500 */

      color: #667085;

      @media screen and (min-width: ${commonStyle.pc}) {
        margin: 0;

        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        /* identical to box height, or 200% */

        /* Gray/500 */

        color: #667085;
      }
    }
  }
`;
