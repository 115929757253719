import styled from "styled-components";

const Header = () => {
  return <HeaderWrap>header</HeaderWrap>;
};

export default Header;

const HeaderWrap = styled.header`
  height: 48px;
  border-bottom: 1px solid red;
`;
