import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import KeytalkButtonIcon from "./components/assets/KeytalkButtonIcon";
import TrandLogo from "./components/assets/TrandLogo";
import { commonStyle } from "./components/assets/common";

function App() {
  return (
    <div className='App'>
      {/* <Header /> */}
      <MainWrap>
        <div className='container'>
          <div className='logo-wrap'>
            <TrandLogo />
          </div>
          <div className='heading-wrap'>
            <span>Coming Soon</span>
            <h1>
              <strong>Prompt</strong>
              <span>Trends</span>
            </h1>
            <p>{`Live ranking of trending expressions for movie, beauty, travel,
comics, and many more.
Search thousands of real-life “Keytalk” expressions as the 
inspiration for your prompt engineering.`}</p>
            <div className='keytalk-icon'>
              <a
                href='https://www.mycelebs.com/'
                target='_blank'
                rel='noreferrer'
              >
                <KeytalkButtonIcon />
              </a>
            </div>
            <a className='contact-alink' href='mailto:sales@mycelebs.com'>
              Contact us
            </a>
          </div>
          <div className='img-wrap'>
            <img src='/images/mainbgimg.png' alt='main img' />
            <img
              className='tablet'
              src='/images/mainbgimg-tablet.png'
              alt='main img'
            />
            <img
              className='mobile'
              src='/images/mainbgimg-mobile.png'
              alt='main img'
            />
          </div>
        </div>
      </MainWrap>
      <Footer />
    </div>
  );
}

export default App;

const MainWrap = styled.main`
  /* min-height: calc(100vh - 48px); */
  /* max-height: fit-content; */
  /* min-height: fit-content; */
  /* max-height: 500px; */
  background: linear-gradient(
      63.43deg,
      rgba(52, 148, 230, 0.15) 0%,
      rgba(236, 110, 173, 0.15) 83.33%
    ),
    #ffffff;
  padding: 0 0;
  overflow: hidden;

  @media screen and (${commonStyle.tablet}) {
    background: linear-gradient(
        63.43deg,
        rgba(52, 148, 230, 0.15) 0%,
        rgba(236, 110, 173, 0.15) 83.33%
      ),
      #ffffff !important;
    padding: 0 0 !important;
  }
  @media screen and (min-width: ${commonStyle.pc}) {
    background: linear-gradient(
        63.43deg,
        rgba(52, 148, 230, 0.15) 0%,
        rgba(236, 110, 173, 0.15) 83.33%
      ),
      #ffffff;
    /* overflow-y: scroll; */
    overflow: hidden;
    padding: 0 64px;
  }
  > .container {
    /* height: 800px; */
    display: flex;
    /* @media screen and (${commonStyle.tablet}) { */
    display: flex;
    width: 100%;
    align-items: flex-start !important;
    flex-direction: column !important;
    /* } */
    padding: 120px 0 48px !important;
    @media screen and (min-width: ${commonStyle.pc}) {
      max-width: 1440px;
      margin: 0 auto;
      /* border: 1px solid blue; */
      position: relative;
      padding: 96px 0;
      align-items: center;
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
    }

    /* align-items: center; */
    > .logo-wrap {
      position: absolute;
      top: 48px;
      padding: 0 16px;
      @media screen and (${commonStyle.tablet}) {
        padding: 0 32px;
      }

      @media screen and (min-width: ${commonStyle.pc}) {
        padding: 0;
        top: 40px;
      }
    }
    > .heading-wrap {
      display: flex !important;
      flex-direction: column;
      order: 1;
      padding: 0 16px;
      @media screen and (${commonStyle.tablet}) {
        padding: 0 32px;
      }
      @media screen and (min-width: ${commonStyle.pc}) {
        order: 0;

        display: block;
        padding: 111px 0;
      }
      > span {
        font-family: "Inter";
        font-style: italic;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 36px;
        /* identical to box height, or 150% */

        letter-spacing: -0.02em;

        color: #242a2f;
        margin-bottom: 8px;
        @media screen and (min-width: ${commonStyle.pc}) {
          font-family: "Inter";
          font-style: italic;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          /* identical to box height, or 150% */

          letter-spacing: -0.02em;

          color: #242a2f;
          margin-bottom: 16px;
          display: block;
        }
      }
      > h1 {
        margin: 0;
        margin-bottom: 16px;
        font-family: "Inter";
        @media screen and (min-width: ${commonStyle.pc}) {
          margin-bottom: 24px;
        }

        > strong {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 58px;
          /* identical to box height, or 121% */

          letter-spacing: -0.04em;

          color: #101828;
          margin-right: 8px;
          @media screen and (min-width: ${commonStyle.pc}) {
            font-weight: 700;
            font-size: 80px;
            line-height: 90%;
            font-family: "Inter";

            /* identical to box height, or 72px */

            letter-spacing: -0.04em;

            color: #101828;
            margin-right: 12px;
          }
        }
        > span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 48px;
          line-height: 58px;
          /* identical to box height, or 121% */

          letter-spacing: -0.02em;

          color: #101828;
          @media screen and (min-width: ${commonStyle.pc}) {
            font-weight: 600;
            font-size: 80px;
            font-family: "Inter";

            line-height: 90%;
            /* identical to box height, or 72px */

            letter-spacing: -0.02em;

            color: #101828;
          }
        }
      }
      > p {
        margin: 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        /* or 133% */

        letter-spacing: -0.02em;

        color: #3d3d4e;
        margin-bottom: 24px;
        @media screen and (min-width: ${commonStyle.pc}) {
          min-width: 560px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          /* or 140% */

          letter-spacing: -0.02em;

          color: #3d3d4e;

          white-space: pre-line;
          margin-bottom: 24px;
        }
      }
      > .keytalk-icon {
        margin-bottom: 32px;

        @media screen and (min-width: ${commonStyle.pc}) {
          margin-bottom: 48px;
        }
        > span {
        }
      }
      > .contact-alink {
        height: 48px;
        background: linear-gradient(0deg, #101828, #101828), #7950f2;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 28px;
        /* identical to box height, or 156% */

        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        letter-spacing: -0.02em;

        /* Base/White */

        color: #ffffff;
        @media screen and (min-width: ${commonStyle.pc}) {
          display: flex;
          width: 330px;
          height: 60px;
          background: linear-gradient(0deg, #101828, #101828), #7950f2;
          /* Shadow/xs */

          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          justify-content: center;
          align-items: center;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          box-sizing: border-box;
          /* identical to box height, or 156% */

          letter-spacing: -0.02em;

          /* Base/White */

          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    > .img-wrap {
      display: flex !important;
      order: 0;
      width: 100%;
      > img {
        width: 100%;
        display: none !important;
        object-fit: cover;
        &.tablet {
          display: none !important;
        }
        &.mobile {
          display: block !important;
        }
      }
      @media screen and (${commonStyle.tablet}) {
        > img {
          width: 100%;
          display: none !important;
          &.tablet {
            display: block !important;
          }
          &.mobile {
            display: none !important;
          }
        }
      }
      @media screen and (min-width: ${commonStyle.pc}) {
        max-width: 748px;
        height: 636px;
        position: relative;
        left: 32px;
        /* padding-right: -35px; */
        display: flex;
        align-items: center;
        > img {
          width: 100%;
          display: block;
          &.tablet {
            display: none;
          }
          &.mobile {
            display: none;
          }
        }
      }
    }
  }
`;
